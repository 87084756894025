import { createContext, Dispatch, SetStateAction, useContext, useState, ReactNode } from "react";

export type PlayerContextType = [string | null, Dispatch<SetStateAction<string | null>>];

const initialState: PlayerContextType = [null, () => {}];

const PlayerContext = createContext(initialState);

interface Props {
  children?: ReactNode;
}

const PlayerProvider: React.FC<Props> = ({ children }) => {
  const state = useState(initialState[0]);
  console.log({ v: state[0] });
  return <PlayerContext.Provider value={state}>{children}</PlayerContext.Provider>;
};

export function usePlayerContext() {
  const context = useContext(PlayerContext);
  if (context === undefined) {
    throw new Error(`usePlayerContext must be used within a PlayerProvider`);
  }
  return context;
}

export default PlayerProvider;
