import type { NextSeoProps } from "next-seo";

import { NextSeo } from "next-seo";
import Head from "next/head";
import { useRouter } from "next/router";
import { SITE_NAME, SITE_URL, DEFAULT_IMAGE } from "@utils/constants";

export type Meta = {
  title: string | null;
  description: string | null;
  image?: string | null;
  path?: string;
  baseUrl?: string;
  keywords?: string;
} & NextSeoProps;

type Props = {
  meta: Meta;
  children: React.ReactNode;
};

export default function Page({ meta, children }: Props) {
  const { locale, locales = [], defaultLocale, asPath } = useRouter();
  const { title, image, baseUrl = SITE_URL, path = asPath, description, keywords } = meta;

  const url = baseUrl + path;

  return (
    <div>
      <NextSeo
        title={title}
        description={description}
        languageAlternates={(locales || []).map((m) => ({
          hrefLang: m,
          href: m === defaultLocale ? url : `${baseUrl}/${locale}${path}`,
        }))}
        canonical={
          locale === defaultLocale ? url : `${baseUrl}/${locale}${path}`
        }
        titleTemplate={`${SITE_NAME} | %s`}
        openGraph={{
          type: "website",
          url,
          title,
          description,
          site_name: SITE_NAME,
          locale,
          images: image
            ? [
              {
                url: image.startsWith("https://")
                  ? image
                  : `${SITE_URL}${image}`,
                width: 600,
                height: 600,
                alt: "Page ",
              },
            ]
            : undefined,
        }}
      />
      <Head>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta
          name="description"
          content={
            description || "The Biggest #Toys App in GCC"}
        />
        <meta
          itemProp="name"
          content="Dabdoob"
        />

        <meta
          property="og:title"
          content={
            title || "Dabdoob"}
        />
        <meta
          property="og:description"
          content={
            description || "The Biggest #Toys App in GCC"}
        />
        <meta
          name="twitter:description"
          content={
            description || "The Biggest #Toys App in GCC"}
        />
        <meta
          name="twitter:title"
          content={
            title || "Dabdoob"}
        />

        <meta
          itemProp="image"
          content={
            image ||
            DEFAULT_IMAGE
          }
        />
        <meta
          property="og:image"
          content={
            image ||
            DEFAULT_IMAGE
          }
        />
        <meta
          property="og:image:secure_url"
          content={
            image ||
            DEFAULT_IMAGE
          }
        />
        <meta
          name="twitter:image"
          content={
            image ||
            'https://dabdoobcdn.fra1.cdn.digitaloceanspaces.com/branding/main-logo-white.png'
          }
        />
        <meta name="keywords" content={keywords ?? "dabdoob"} />
        <meta property="og:image:width" content="600" />
        <meta property="og:image:height" content="600" />

        <meta name="twitter:domain" content="dabdoob.com" />
        <meta name="twitter:site" content="@dabdoob" />
        <meta name="robots" content="index" />
        <meta
          itemProp="url"
          content={
            locale === defaultLocale ? url : `${baseUrl}/${locale}${path}`
          }
        />
        <meta
          property="og:url"
          content={
            locale === defaultLocale ? url : `${baseUrl}/${locale}${path}`
          }
        />
        <meta
          name="twitter:url"
          content={
            locale === defaultLocale ? url : `${baseUrl}/${locale}${path}`
          }
        />
        <meta name="twitter:card" content="summary" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <meta name="HandheldFriendly" content="true" />
        <link
          rel="shortcut icon"
          href="/favicon.ico"
          type="image/x-icon"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/manifest.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#d41114" />
        <meta name="msapplication-TileColor" content="#008AF6" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="google-site-verification" content="KHdrfR9uLhJFfIFgXe2V4pJegsOQLRr5fUeHVeo7Ew8" />
      </Head>
      {children}
    </div>
  );
}
